import Vue from 'vue'
import Vuex from 'vuex'
import {getTimeInterval} from '../utils/index'
import {fetchSocial, fetchSiteInfo, fetchCarousel, fetchTag, fetchBookmarkList, fetchRecommendArticle} from '@/api'
import secret from '../utils/secret.js'

Vue.use(Vuex)
// 略:后台获取系统运行时间
const runAt = '1640966400000';
let timer = null;
const state = {
    runTimeInterval: '',
    socials: '',
    websiteInfo: '',
    info: '',
}
const mutations = {
    SET_SOCIALS: (state, v) => {
        state.socials = v;
    },
    SET_CAROUSELS: (state, v) => {
        state.carousels = v;
    },
    SET_TAGS: (state, v) => {
        state.tags = v;
    },
    SET_RECOMMEND: (state, v) => {
        state.recommend = v;
    },
    SET_FRIENDS: (state, v) => {
        state.friends = v;
    },
    SET_SITE_INFO: (state, v) =>{
        state.websiteInfo = v;
    },
    GET_RUNTIME_INTERVAL: (state) => {
        if (!timer || !state.runTimeInterval) {
            clearInterval(timer)
            timer = setInterval(() => {
                state.runTimeInterval = getTimeInterval(runAt);
            }, 1000);
        }
    },
    SET_INFO(state, payload) {
        state.info = payload;
    }
}
const actions = {
    initComputeTime: ({commit}) => {
        commit('GET_RUNTIME_INTERVAL');
    },
    getSiteInfo: ({commit,state}) =>{
        return new Promise(resolve => {
            if (state.websiteInfo){
                resolve(state.websiteInfo)
            }else {
                fetchSiteInfo().then(res => {
                    let data = res.data || {}
                    commit('SET_SITE_INFO',data);
                    resolve(data);
                }).catch(() => {
                    resolve({});
                })
            }
        })
    },
    getStoreSocials: ({commit,state}) =>{
        return new Promise((resolve => {
            if (state.socials){
                resolve(state.socials)
            } else {
                fetchSocial().then(res =>{
                    if (res.code === 200) {
                        let results = secret.changeJson(res.data)
                        let data = results || []
                        commit('SET_SOCIALS',data);
                        resolve(data);
                    }
                }).catch(() =>{
                    resolve([]);
                })
            }
        }))
    },
    getStoreCarousel: ({commit,state}) =>{
        return new Promise((resolve => {
            if (state.carousels){
                resolve(state.carousels)
            } else {
                fetchCarousel().then(res =>{
                    if (res.code === 200) {
                        let results = secret.changeJson(res.data)
                        let data = results.list || []
                        commit('SET_CAROUSELS',data);
                        resolve(data);
                    }
                }).catch(() =>{
                    resolve([]);
                })
            }
        }))
    },
    getStoreTag: ({commit,state}) =>{
        return new Promise((resolve => {
            if (state.tags){
                resolve(state.tags)
            } else {
                fetchTag().then(res =>{
                    if (res.code == 200) {
                        let results = secret.changeJson(res.data)
                        let data = results.list || []
                        commit('SET_TAGS',data);
                        resolve(data);
                    }
                }).catch(() =>{
                    resolve([]);
                })
            }
        }))
    },
    getStoreRecommend: ({commit,state}) =>{
        return new Promise((resolve => {
            // if (state.recommend){
            //     resolve(state.recommend)
            // } else {
                fetchRecommendArticle().then(res =>{
                    if (res.code === 200) {
                        let results = secret.changeJson(res.data)
                        let data = results.list || []
                        commit('SET_RECOMMEND', data);
                        resolve(data);
                    }
                }).catch(() =>{
                    resolve([]);
                })
            // }
        }))
    },
    getStoreFriend: ({commit,state}) =>{
        return new Promise((resolve => {
            if (state.friends){
                resolve(state.friends)
            } else {
                fetchBookmarkList().then(res =>{
                    if (res.code === 200) {
                        let results = secret.changeJson(res.data)
                        let data = results.list || []
                        commit('SET_FRIENDS',data);
                        resolve(data);
                    }
                }).catch(() =>{
                    resolve([]);
                })
            }
        }))
    },
}
const getters = {
    runTimeInterval: state => state.runTimeInterval,
    info(state) {
        return state.info;
    },
}
export default new Vuex.Store({
    state,
    mutations,
    actions,
    modules: {},
    getters
})
