<template>
    <div id="app" v-cloak>
        <router-view />
    </div>
</template>
<script>
    export default {
        name: "app",
        data() {
            return {
            }
        }
    }
</script>
<style lang="less">
    #app {
        font-size: 17px;
        color: #6f6f6f;
        .control {
            position: absolute;
            top:200px;
            width: 100px;
            height: 100px;
            background-color: #3a8ee6;
            z-index: 999;
        }
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
</style>
