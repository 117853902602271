import http from '@/utils/request'

export function fetchList(params) {
    return http.post('api/article/list',params)
}

export function fetchArticle(params) {
    return http.post('api/article/info',params)
}

export function fetchFriend() {
    return http.post('api/bookmark/list',{})
}

export function fetchRecommendArticle() {
    return http.post('api/article/recommendList',{})
}

export function fetchBookmarkList() {
    return http.post('api/bookmark/list',{})
}

export function fetchBookmarkListV2() {
    return http.post('api/bookmark/listV2',{})
}

export function fetchCarousel() {
    return http.post('api/carousel/list',{})
}

export function fetchCategory() {
    return http.post('api/category/list',{})
}

export function fetchComment(params) {
    return http.post('api/comment/list',params)
}

export function addComment(params) {
    return http.post('api/comment/add',params)
}

export function fetchPhotos(params) {
    return http.post('api/photo/list',params)
}

export function fetchSiteInfo() {
    return http.post('api/site/info',{})
}

export function fetchTag() {
    return http.post('api/tags/list',{})
}

export function fetchBloguser() {
    return http.post('api/user/info',{})
}

export function fetchMusics(params) {
    return http.post('api/blog_music/list', params)
}

export function fetchAlbumList(params) {
    return http.post('api/blog_album/list', params)
}

export function fetchSocial() {
    return http.post('api/social/list',{})
}

export function click(params) {
    return http.post('api/click',params)
}
