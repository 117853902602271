import axios from 'axios'
// import store from '@/store'
import qs from 'qs'
import {getToken, getClientToken, setClientToken} from '@/utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // 控制显示调试页面
        // config.headers['X-Debug-Mode'] = 1
        // do somethi
        // ng before request is sent
        if (getToken()) {
            config.headers['Authorization'] = 'Bearer ' + getToken()
        }
        // ng before request is sent
        if (!getClientToken()) {
            setClientToken()
        }
        config.headers['Request-Token'] = getClientToken()
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data

        // if the custom code is not 20000, it is judged as an error.
        // if (res.code !== 20000) {
        //     return Promise.reject(new Error(res.message || 'Error'))
        // } else {
            return res
        // }
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

// 请求方法 json
const http = {
    postJson(url, params) {
        return service.post(url, params, {
            transformRequest: [(params) => {
                return JSON.stringify(params)
            }],
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    post(url, params) {
        return service.post(url, params, {
            transformRequest: [(params) => {
                return qs.stringify(params)
            }],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    put(url, params) {
        return service.put(url, params, {
            transformRequest: [(params) => {
                return JSON.stringify(params)
            }],
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    get(url, params) {
        return service.get(url, {
            params: params,
            paramsSerializer: (params) => {
                return qs.stringify(params)
            }
        })
    },
}

export default http
