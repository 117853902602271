import Cookies from 'js-cookie'

const TokenKey = 'access_token'
const RequestToken = 'request_token'

function generateUniqueValue() {
  const timestamp = Date.now(); // 获取当前时间的毫秒数
  const random = Math.floor(Math.random() * 1000000); // 生成一个随机数
  return `${timestamp}${random}`; // 将时间戳和随机数拼接在一起作为唯一值
}

export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}

// 客户端cookie
export function getClientToken() {
  return Cookies.get(RequestToken)
}

// 设置客户端cookie
export function setClientToken() {
  const uniqueValue = generateUniqueValue(); // 生成唯一值
  const expires = 1; // 过期时间为1天
  return Cookies.set(RequestToken, uniqueValue, { expires });
}

