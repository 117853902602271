<template>
<!-- 主页  除登录页-->
    <div id="main">
        <layout-header></layout-header>
        <layout-body></layout-body>
        <layout-footer></layout-footer>
    </div>
</template>

<script>
    import layoutHeader from '@/components/layout/layout-header'
    import layoutBody from '@/components/layout/layout-body'
    import layoutFooter from '@/components/layout/layout-footer'
    export default {
        name: "layout",
        components: {
            layoutHeader,
            layoutBody,
            layoutFooter,
        },
        data() {
            return {
            }
        }
    }
</script>

<style scoped>
    .target-fix {
        position: relative;
        top: -60px;
        display: block;
        height: 0;
        overflow: hidden;
    }

</style>
