import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import layout from '@/components/layout'
let keywords = 'yueguang,悦,www.iyueguang.cn,\n' +
    '    php,mysql,vue,linux,php,go,java,mongodb,redis,memcache,nginx,git,算法,数据库优化,\n' +
    '    sql,insert,select,update,\n' +
    '    学习网站,模板,案例,TP5,thinkphp,音乐,流行音乐,音乐下载,music,资源';
let description = 'yueguang,悦,www.iyueguang.cn,\n' +
    '    php,mysql,vue,linux,php,go,java,mongodb,redis,memcache,nginx,git,算法,数据库优化,\n' +
    '    sql,insert,select,update,\n' +
    '    学习网站,模板,案例,TP5,thinkphp,音乐,流行音乐,音乐下载,music,资源';
const routes = [
    {
        path: '/',
        component: layout,
        redirect: '/home',
        children: [
            {
                path: '/home',
                component: () => import('@/views/HomePage.vue'),
                name: 'HomePage',
                meta: {
                    title: 'I',
                    content:{
                        keywords: keywords,
                        description: description,
                    }
                }
            },
            {
                path: '/default',
                component: () => import('@/views/DefaultPage.vue'),
                name: 'DefaultPage',
                meta: {
                    title: 'default',
                    content:{
                        keywords: keywords,
                        description: description,
                    }
                }
            }
        ]
    },
    {
        path: '/login',
        component: layout,
        name: 'LoginLayout',
        children: [
            {
                path: '/login',
                component: () => import('@/views/LoginPage.vue'),
                name: 'LoginPage',
                meta: {
                    title: '登录/注册',
                    content:{
                        keywords: '猫头鹰登录页,可爱,cute,' + keywords,
                        description: '猫头鹰登录页,可爱,cute,' + description,
                    }
                }
            }
        ],
        meta: {
            title: '登录页',
            content:{
                keywords: keywords,
                description: description,
            }
        }
    },
    {
        path: '/register',
        component: layout,
        name: 'registerLayout',
        children: [
            {
                path: '/register',
                component: () => import('@/views/RegisterPage.vue'),
                name: 'registerPage',
                meta: {
                    title: '登录/注册',
                    content:{
                        keywords: '猫头鹰登录页,可爱,cute,' + keywords,
                        description: '猫头鹰登录页,可爱,cute,' + description,
                    }
                }
            }
        ],
        meta: {
            title: '注册页',
            content:{
                keywords: keywords,
                description: description,
            }
        }
    },
    {
        path: '/category/:category/:cate_id',
        component: layout,
        name: 'CategoryLayout',
        children: [
            {
                path: '/category/:category/:cate_id',
                component: () => import('@/views/HomePage.vue'),
                name: 'CategoryPage',
                meta: {
                    title: '分类',
                    content:{
                        keywords: keywords,
                        description: description,
                    }
                }
            }
        ],
        meta: {
            title: '分类',
            content:{
                keywords: keywords,
                description: description,
            }
        }
    },
    {
        path: '/tag/:tag/:tag_id',
        component: layout,
        name: 'TagLayout',
        children: [
            {
                path: '/tag/:tag/:tag_id',
                component: () => import('@/views/HomePage.vue'),
                name: 'TagPage',
                meta: {
                    title: '标签',
                    content:{
                        keywords: keywords,
                        description: description,
                    }
                }
            }
        ],
        meta: {
            title: '标签',
            content:{
                keywords: keywords,
                description: description,
            }
        }
    },
    {
        path: '/search/:words',
        component: layout,
        name: 'SearchLayout',
        children: [
            {
                path: '/search/:words',
                component: () => import('@/views/HomePage.vue'),
                name: 'SearchPage',
                meta: {
                    title: '搜索',
                    content:{
                        keywords: keywords,
                        description: description,
                    }
                }
            }
        ],
        meta: {
            title: '搜索',
            content:{
                keywords: keywords,
                description: description,
            }
        }
    },
    {
        path: '/about',
        component: layout,
        name: 'AboutLayout',
        children: [
            {
                path: '/about',
                component: () => import('@/views/AboutPage.vue'),
                name: 'AboutPage',
                meta: {
                    title: '关于',
                    content:{
                        keywords: keywords,
                        description: description,
                    }
                }
            }
        ],
        meta: {
            title: '关于',
            content:{
                keywords: keywords,
                description: description,
            }
        }
    },
    {
        path: '/bookmark',
        component: layout,
        name: 'BookmarkLayout',
        children: [
            {
                path: '/bookmark',
                component: () => import('@/views/Bookmark.vue'),
                name: 'BookmarkPage',
                meta: {
                    title: 'bookmark',
                    content:{
                        keywords: keywords,
                        description: description,
                    }
                }
            }
        ],
        meta: {
            title: '书签页',
            content:{
                keywords: keywords,
                description: description,
            }
        }
    },
    {
        path: '/photo_base',
        component: layout,
        name: 'PhotosPageLayout',
        children: [
            {
                path: '/photo_base',
                component: () => import('@/views/PhotosPage.vue'),
                name: 'PhotosPage',
                meta: {
                    title: 'photo',
                    content:{
                        keywords: '美图,美女,动漫,二次元,壁纸,风景,动物,' + keywords,
                        description: '美图,美女,动漫,二次元,壁纸,风景,动物,' + description,
                    }
                }
            }
        ],
        meta: {
            title: '我的图库',
            content:{
                keywords: '美图,美女,动漫,二次元,壁纸,风景,动物,' +  keywords,
                description: '美图,美女,动漫,二次元,壁纸,风景,动物,' +  description,
            }
        }
    },
    {
        path: '/phpfuncs',
        component: layout,
        name: 'PhpFuncLayout',
        children: [
            {
                path: '/phpfuncs',
                component: () => import('@/views/PhpFuncPage.vue'),
                name: 'PhpFuncPage',
                meta: {
                    title: 'phpfuncs',
                    content:{
                        keywords: 'php函数,' + keywords,
                        description: 'php函数,' + description,
                    }
                }
            }
        ],
        meta: {
            title: 'PHP函数速查表',
            content:{
                keywords: 'php函数,' + keywords,
                description: 'php函数,' + description,
            }
        }
    },
    {
        path: '/friend',
        component: layout,
        name: 'FriendLayout',
        children: [
            {
                path: '/friend',
                component: () => import('@/views/FriendPage.vue'),
                name: 'FriendPage',
                meta: {
                    title: 'friend',
                    content:{
                        keywords: keywords,
                        description: description,
                    }
                }
            }
        ],
        meta: {
            title: '友链',
            content:{
                keywords: keywords,
                description: description,
            }
        }
    },
    {
        path: '/article/:id',
        component: layout,
        name: 'ArticlesLayout',
        children: [
            {
                path: '/article/:id',
                component: () => import('@/views/ArticlesPage.vue'),
                name: 'ArticlesPage',
                meta: {
                    title: '文章',
                    content:{
                        keywords: keywords,
                        description: description,
                    }
                }
            }
        ],
    },
    {
        path: '/external_page',
        component: layout,
        name: 'ExternalPageLayout',
        children: [
            {
                path: '/external_page',
                component: () => import('@/views/ExternalPage.vue'),
                name: 'ExternalPage',
                meta: {
                    title: '文章',
                    content:{
                        keywords: keywords,
                        description: description,
                    }
                }
            }
        ],
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    let title = "YueGuang’s blog"
    if(to.meta.content){
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }
    title = `${to.meta.title} - ${title}`
    document.title = title
    next();
})
export default router
