<template>
    <div class="section-title"><h4><slot></slot></h4></div>
</template>

<script>
    export default {
        name: "section-title"
    }
</script>

<style scoped>
    .section-title {
        font-weight: bolder;
        background-color: #ecf8ff;
        border-left: 5px solid #50bfff;
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin-bottom: 10px;
        padding: 0 12px;
    }
</style>
