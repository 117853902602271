// 在引入 Vue.js 之前将 setImmediate 和 MessageChannel 设置为 undefined
window.setImmediate = undefined;
window.MessageChannel = undefined;

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/style.less'
import './assets/font/iconfont.css'
import './assets/custom/iconfont.css'
import {parseTime} from './utils'
import axios from './utils/request.js'
import secret from './utils/secret.js'
import top from './utils/top.js'

import prismjs from '@/assets/prism/prism'
import prismCss from '@/assets/prism/prism.css'
Vue.use(prismjs)
Vue.use(prismCss)

import APlayer from '@moefe/vue-aplayer';

Vue.use(APlayer, {
  defaultCover: 'https://github.com/u3u.png',
  productionTip: true,
});

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.prototype.$top = top;
Vue.prototype.$secret = secret;
Vue.prototype.$http = axios;
Vue.prototype.$url = 'https://www.iyueguang.cn';
Vue.prototype.$prismjs = prismjs;

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element)
Vue.config.productionTip = false
Vue.filter('parseTime', (v) => parseTime(v,'{y}-{m}-{d}'))
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

