import CryptoJS from 'crypto-js'

const iv = '';

function encrypt(text, secret_key){
    var encrypted = CryptoJS.AES.encrypt(text,CryptoJS.enc.Utf8.parse(secret_key),{
        iv:CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
}

function decrypt(text, secret_key){
    var decryptd = CryptoJS.AES.decrypt(text,CryptoJS.enc.Utf8.parse(secret_key),{
        iv:CryptoJS.enc.Utf8.parse(iv),
        mode:CryptoJS.mode.ECB,
        padding:CryptoJS.pad.Pkcs7
    })
    return decryptd.toString(CryptoJS.enc.Utf8)
}

function createAeskey(lengths)
{
    let str = ''
    let strPol = "abcdefghijklmnopqrstuvwxyz"
    let max = strPol.length -1
    for (let i = 0; i < lengths; i++) {
        let rand = Math.floor(Math.random()*(max+1))
        str += strPol[rand]
    }
    return str
}

function changeJson(token_str)
{
    let header_key = token_str.substr(0, 8)
    let footer_key = token_str.substr(-8)
    token_str = token_str.substr(8)
    token_str = token_str.substr(0, token_str.length - 8)
    let secret_key = header_key + footer_key
    return JSON.parse(decrypt(token_str,secret_key))
}

function changeTokenStr(json_data)
{
    let header_key = createAeskey(8).toUpperCase()
    let footer_key = createAeskey(8).toUpperCase()
    let secret_key = header_key + footer_key
    return header_key + encrypt(json_data, secret_key) + footer_key
}

export default {
    changeJson,
    changeTokenStr
}
